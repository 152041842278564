export const environment = {
  type: 'staging',

  serverDomainName: 'https://api-modernity.sompom.com',
  imageProjectPrefix: 'mod-b2b-dev',

  // socket
  socketHost: 'sc-modernity.sompom.com',
  socketPort: 443,
  socketSecure: true,
};
